html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.full-screen-background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-image
{
  background-image: url('../images/Banner.png');
  background-position: cover;
  position: absolute;
  top: 0;
}

.bg-color {
  background-color: #44c0a8 !important;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.top-5 {
  top: 5%;
}

.top-10 {
  top: 10%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2 {
  bottom: 2%;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}

.allow-overlap {
  z-index: 1;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.justify-start {
  justify-content: start;
}
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.relative-top {
  top: 10%;
}

.font-relative {
  font-size: 50px !important;
}

.text-center {
  text-align: center;
}
.relative-width {
  width: 60%;
}
.text-shadow {
  text-shadow: 3px 3px 0px #390c06 !important;
}
.heading-relative {
  font-size: 80px !important;
}
.relative-banner-width-height {
  width: 70%;
  height: 300px;
}
.bannerText {
  font-size: 20px !important;
  width: 100%;
  top: 7%;
}
.orange-banner {
  width: 100%;
  height: 450px;
}

.small-footer {
  width: 100%;
  visibility: hidden;
}

.large-footer {
  width: 100%;
  visibility: hidden;
}

.btn-text {
  color: black !important;
  text-decoration: none;
}
.live-logo {
  width: 100px;
}

.banner-width {
  width: 80%;
}

.homeTop,.registerTop{
  top:18%
}

@media (max-width: 424px) {
  .font-relative {
    font-size: 8px !important;
  }
  .relative-width {
    width: 50%;
  }
  .heading-relative {
    font-size: 25px !important;
  }
  .bannerText {
    font-size: 13px !important;
    width: 190px;
  }
  .small-footer {
    visibility: visible;
    height: 300px;
  }
 .registerTop{
    top:0%
  }
  .homeTop{
    top:3%
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .font-relative {
    font-size: 8px !important;
  }
  .relative-width {
    width: 50%;
  }
  .heading-relative {
    font-size: 30px !important;
  }
  .bannerText {
    font-size: 15px !important;
    width: 250px;
    top: 10%;
  }
  .small-footer {
    visibility: visible;
    height: 300px;
  }
  .live-logo {
    width: 80px;
  }
  .orange-banner {
    height: 600px;
  }
  .registerTop{
    top:0%
  }

  .homeTop{
    top:4%
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .font-relative {
    font-size: 12px !important;
  }
  .relative-width {
    width: 45%;
  }
  .heading-relative {
    font-size: 50px !important;
  }
  .bannerText {
    font-size: 16px !important;
    width: 400px;
    top: 18%;
  }
  .orange-banner {
    height: 450px;
  }
  .large-footer {
    visibility: visible;
  }
  .small-footer {
    visibility: hidden;
  }
  .homeTop{
    top:5%
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .font-relative {
    font-size: 20px !important;
  }
  .relative-width {
    width: 50%;
  }
  .bannerText {
    font-size: 20px !important;
    width: 450px;
  }
  .large-footer {
    visibility: visible;
  }
  .small-footer {
    visibility: hidden;
  }
}

@media (min-width: 1401px) {
  .orange-banner {
    width: 100%;
    height: 450px;
  }
  .large-footer {
    visibility: visible;
  }
  .small-footer {
    visibility: hidden;
  }
  .font-relative {
    font-size: 25px !important;
  }
  .bannerText {
    font-size: 20px !important;
    width: 600px;
    top: 18%;
  }
  .relative-banner-width-height {
    width: 700px;
  }
  .banner-width {
    width: 100%;
  }
}