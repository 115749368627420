
@media (min-width: 320px) and (max-width: 423px) {

  .mobileFooterHeight{
    height: 250px;

  }
   }

@media (min-width: 424px) and (max-width: 529px) {
 .mobileFooterHeight{
    height: 250px;

  }
}

@media (min-width: 530px) and (max-width: 767px) {

    
     .mobileFooterHeight{
        height: 300px;
    
      } 
   }