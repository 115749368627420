.font-modesto-condensed {
  font-family: 'modesto-condensed' !important;
}

.font-texturina {
  font-family: 'Texturina' !important;
}

.orangeMidPoint {
}
/* .regCenter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .navContainer {
  margin-top: -5px;
} */

/* .register-page-container {
  height: 248vh;
  position: relative;
} */
.header-base {
  width: 120%;
  height: 120px;
  margin-top: -35px;
}
.banner-width {
  margin-top: 20px;
  }
.page-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logoSize {
  width: 250px;
}

.texturinaFont {
  font-family: "Texturina" !important;
  letter-spacing: 0px !important;
}

.boltBtn {
  font-size: 40px !important;
  margin-top: 20px;
}

.homeBtn {
  font-family: "modesto-condensed" !important;
  font-size: 40px !important;
  text-decoration: none !important;
  color: black !important;
  font-weight: 800 !important;
}
.regHomeBtn {
  font-family: "modesto-condensed" !important;
  font-size: 40px !important;
  text-decoration: none !important;
  color: black !important;
  font-weight: 800 !important;
}

.midHeading {
  text-align: center !important;
  font-family: "modesto-condensed" !important;
  font-size: 72px !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-shadow: 3px 3px 0px #390c06 !important;
  font-size: 72px !important;
}

.regMidHeading {
  margin-top: 40px !important;
  text-align: center !important;
  font-family: "modesto-condensed" !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-shadow: 3px 3px 0px #390c06 !important;
  font-size: 40px !important;
}

.signUpBtn {
  background-color: #44c0a8;
}

.registerTextfield {
  width: 180px;
  background-color: #ffffff;
  line-height: inherit;
}

.registerBox {
  border: 1px solid;
  width: 150px;
  background-color: #ffffff;
}

.rotate {
  transform: rotate(180deg);
}

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.headerBaseStyle {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.regHeaderBaseStyle {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.mainBaseStyle {
  position: relative;
}

.headerBtn {
  top: 0;
  position: fixed;
  z-index: 9999;
}
.regHeaderBtn {
  top: 0;
  position: fixed;
  z-index: 9999;
}
.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.sideBorder {
  top: 80px;
  position: fixed;
}

.registerSideBorder {
  bottom: 0;
  top: 0;
  position: fixed;
  width: 100%;
}

.midBanner {
  top: 5%;
}
.regMidBanner {
  top: 0;
}

/* .mainLogo {
  top: 390px;
  width: 30%;
} */

.regMainLogoBox {
  top: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.regMainLogo {
  width: 500px;
}

.school {
  top: 85%;
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
}

.schoolDesc {
  width: 70%;
}

.regSchool {
  top: 580px;
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
}

.regSchoolDesc {
  font-family: "Texturina" !important;
  font-size: 20px;
  width: 250px;
}

.midBannerHead {
  top: 750px;
}

.regMidBannerHead {
  top: 650px;
}

.midBannerDesc {
  top: 87%;
  width: 30%;
  text-align: center;
}

.regMidHeadDesc {
  width: 400px;
}

.midHeading {
  font: "normal normal normal 72px/64px Modesto Condensed";
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 3px 3px 0px #390c06;
  opacity: 1;
}

.textBanner {
  width: 700px;
}

.secondTextBanner {
  width: 700px;
}

.bannerBrief {
  position: absolute;
  top: 920px;
  width: 500px;
  text-align: center;
  font-family: "Texturina" !important;
}

.secondBannerBrief {
  top: 400px;
  width: 500px;
  text-align: center;
}

.secBriefPos {
  top: 2010px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.secondBannerSubBrief {
  font-family: "Texurina" !important;
  width: 500px;
  text-align: center;
}

.orangeBand {
  top: 130%;
  width: 100%;
}

.orangeDesc {
  top: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.midDesc1 {
  text-shadow: 3px 3px 0px #390c06 !important;
  font-family: "modesto-open-caps" !important;
  font-size: 120px !important;
  color: white;
}

.midDesc2 {
  text-shadow: 3px 3px 0px #390c06 !important;
  font-family: "modesto-condensed" !important;
  font-size: 40px !important;
  color: white;
}

.belowOrangeBand {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.signUpBtnPos {
  margin-top: 10px !important;
  background-color: #44c0a8 !important;
  font-family: "modesto-condensed" !important;
}

.footerBase {
  top: 305%;
  width: 100%;
}

.registerFooterBase {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.borderLine {
  position: absolute;
  bottom: 0;
  width: 60%;
}

.registerFooterBox {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.footerBox {
  top: 2600px;
  text-align: center;
}
.registerBanner {
  top: 800px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.regBannerSize {
  margin-top: 40px;
  width: 600px;
}

.formStyle {
  position: absolute;
  /* top: 120px; */
  display: flex;
  justify-content: center;
  width: 100%;
}

.regBoxSize {
}

.BMSLogo {
  width: 120px;
  height: 120px;
}

.hamburger {
  background-image: url('../images/Home/Hamburger.png');
  background-size: 115% ;
}

@media (min-width: 280px) and (max-width: 374px) {
  /* .navContainer {
    margin-bottom: -15px;
  }
  .register-page-container {
    height: 650px;
  } */
  .registerTextfield {
    font-size: 10px;
    width: 60px;
  }
  .regHeaderBtn {
    top: 0px;
  }
  .headerBtn {
    top: 0px;
  }
  .headerBaseStyle {
    width: 77%;
  }
  .homeBtn {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .boltBtn {
    height: 12px !important;
    margin-top: -5px;
  }
  /* .mainLogo {
    top: 18%;
    width: 30%;
  } */
  .textBanner {
    top: 200px;
    width: 180px;
  }
  .secondTextBanner {
    width: 190px;
  }
  .secondBannerSubBrief {
    width: 150px;
    font-size: 6px !important;
  }
  .secBriefPos {
    top: 410px;
  }
  .signUpBtnPos {
    font-size: 6px !important;
    margin-top: 2px !important;
    padding: 0px !important;
  }
  .midBannerHead {
    top: 150px;
  }
  .midHeading {
    font-size: 14px !important;
  }
  /* .school {
    top: 26%;
  } */
  .schoolDesc {
    font-size: 5px !important;
  }

  .bannerBrief {
    top: 180px;
    width: 150px;
    font-size: 5px !important ;
  }
  .orangeDesc {
    top: 80px;
  }
 

  /* .midDesc1 {
    font-size: 50px !important;
  } */

  /* .midSeparator {
    margin-top: 15px !important;
    margin-left: 10px !important;
  } */

  /* .midDesc2 {
    font-size: 15px !important;
  } */
  
  .regMainLogo {
    width: 150px;
  }
  .regMainLogoBox {
    top: 80px;
  }
  .regSchoolDesc {
    font-size: 5px !important;
    width: 80px;
  }
  .regMidHeading {
    margin-top: 30px !important;
    text-shadow: 1px 1px 0px #390c06 !important;
    font-size: 12px !important;
  }
  .regMidHeadDesc {
    width: 170px;
    font-size: 5px !important;
  }
  .regBannerSize {
    width: 180px;
    margin-top: 20px;
  }
  .registerBox {
    width: 60px;
    height: 20px;
  }
  .regHeaderBtn {
    top: -5px;
  }
  /* .formStyle {
    top: 40px;
  } */
  .logoSize {
    width: 50px;
  }
  .BMSLogo {
    width: 20px;
    height: 20px;
    margin-bottom: -10px;
  }
  .footerBox {
    top: 120%;
  }
  .regHomeBtn {
    font-size: 8px !important;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .hamburger {
    width: 375px;
  }
  .secondTextBanner {
    width: 250px;
  }

  .secBriefPos {
    top: 490px;
    width: 200px;
  }
  .secondBannerSubBrief {
    width: 200px;
    font-size: 8px !important;
  }
  /* .register-page-container {
    height: 90vh;
  } */
  .registerTextfield {
    font-size: 10px;
    width: 60px;
  }
  .regHeaderBtn {
    top: -5px;
  }
  .headerBtn {
    top: 0px;
  }
  .headerBaseStyle {
    width: 77%;
  }
  .homeBtn {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .boltBtn {
    font-size: 10px !important;
    margin-top: -5px;
  }
  /* .mainLogo {
    top: 18%;
    width: 30%;
  } */
  .textBanner {
    top: 35%;
    width: 220px;
  }
  .midBannerHead {
    top: 170px;
  }
  .midHeading {
    font-size: 20px !important;
  }

  .schoolDesc {
    font-size: 5px !important;
  }

  .bannerBrief {
    top: 210px;
    width: 150px;
    font-size: 6px !important ;
  }
  .orangeDesc {
    top: 100px;
  }
  .orangeMidPoint {
    margin-top: 60px;
  }

  /* .midDesc1 {
    font-size: 70px !important;
  }

  .midSeparator {
    margin-top: 25px !important;
    margin-left: 10px !important;
  }

  .midDesc2 {
    font-size: 20px !important;
  } */
  .belowOrangeBand {
    top: 450px;
  }
  .signUpBtnPos {
    font-size: 6px !important;
    margin-top: 4px !important;
    padding: 0 !important;
  }
  .regMainLogo {
    width: 150px;
  }
  .regMainLogoBox {
    top: 80px;
  }
  .regSchoolDesc {
    font-size: 5px !important;
    width: 80px;
  }
  .regMidHeading {
    margin-top: 20px !important;
    text-shadow: 1px 1px 0px #390c06 !important;
    font-size: 12px !important;
  }
  .regMidHeadDesc {
    width: 200px;
    font-size: 5px !important;
  }
  .regBannerSize {
    width: 200px;
    margin-top: 20px;
  }
  .registerBox {
    width: 60px;
    height: 20px;
  }
  .regHeaderBtn {
    top: -5px;
  }
  /* .formStyle {
    top: 40px;
  } */
  .logoSize {
    width: 50px;
  }
  .BMSLogo {
    width: 50px;
    height: 20px;
    margin-bottom: -10px;
  }
  .footerBox {
    top: 120%;
  }
  .regHomeBtn {
    font-size: 8px !important;
  }
}

@media (min-width: 425px) and (max-width: 500px) {
  .hamburger {
    width: 425px;
  }
  .regHomeBtn {
    font-size: 12px !important;
  }
  .logoSize {
    width: 50px;
  }
  .BMSLogo {
    width: 50px;
    height: 20px;
  }
  .footerBox {
    top: 120%;
  }
  /* .register-page-container {
    height: 100vh;
  } */
  .registerTextfield {
    font-size: 12px;
    width: 80px;
  }

  .regMainLogo {
    width: 150px;
  }
  .regMainLogoBox {
    top: 80px;
  }
  .regSchoolDesc {
    font-size: 5px !important;
    width: 80px;
  }
  .regMidHeading {
    margin-top: 40px !important;
    text-shadow: 1px 1px 0px #390c06 !important;
    font-size: 20px !important;
  }
  .regMidHeadDesc {
    width: 200px;
    font-size: 5px !important;
  }
  .regBannerSize {
    width: 230px;
  }
  .registerBox {
    width: 70%;
    height: 20px;
  }
  .regHeaderBtn {
    top: -5px;
  }
  .registerSideBorder {
    width: 90%;
  }
  .headerBtn {
    top: 0px;
  }
  .headerBaseStyle {
    width: 92%;
  }
  .homeBtn {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .boltBtn {
    font-size: 12px !important;
    margin-top: -5px;
  }
  /* .mainLogo {
    top: 15%;
    width: 30%;
  } */
  .textBanner {
    width: 250px;
  }
  .midBannerHead {
    top: 190px;
  }
  .midHeading {
    font-size: 20px !important;
  }
  /* 
  .school {
    top: 24%;
  } */
  .schoolDesc {
    font-size: 5px !important;
  }

  .bannerBrief {
    top: 235px;
    width: 150px;
    font-size: 6px !important ;
  }
  .orangeDesc {
    top: 120px;
    width: 250px;
  }
  .orangeMidPoint {
    margin-top: 60px;
  }
/* 
  .midDesc1 {
    font-size: 70px !important;
  }

  .midSeparator {
    margin-top: 25px !important;
    margin-left: 10px !important;
  }

  .midDesc2 {
    font-size: 20px !important;
  } */
  .belowOrangeBand {
    top: 500px;
  }

  .secondTextBanner {
    width: 250px;
  }
  .secondBannerSubBrief {
    width: 220px;
    font-size: 8px !important;
  }

  .secBriefPos {
    top: 540px;
  }
  .signUpBtnPos {
    top: 100.5% !important;
    width: 20px !important;
    height: 10px !important;
    font-size: 10px !important;
  }
  /* .formStyle {
    top: 60px;
  } */
  .regBoxSize {
    width: 250px !important;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .regHomeBtn {
    font-size: 12px !important;
  }
  .logoSize {
    width: 50px;
  }
  .BMSLogo {
    width: 50px;
    height: 20px;
  }

  /* .register-page-container {
    height: 1000px;
  } */
  .registerTextfield {
    font-size: 12px;
    width: 80px;
  }

  .regMainLogo {
    width: 150px;
  }
  .regMainLogoBox {
    top: 160px;
  }
  .regSchoolDesc {
    font-size: 5px !important;
    width: 80px;
  }
  .regMidHeading {
    margin-top: 40px !important;
    text-shadow: 1px 1px 0px #390c06 !important;
    font-size: 20px !important;
  }
  .regMidHeadDesc {
    width: 200px;
    font-size: 5px !important;
  }
  .regBannerSize {
    width: 230px;
  }
  .registerBox {
    width: 80px;
    height: 20px;
  }
  .regHeaderBtn {
    top: -5px;
  }
  .registerSideBorder {
    width: 90%;
  }
  .headerBtn {
    top: 0px;
  }
  .headerBaseStyle {
    width: 92%;
  }
  .homeBtn {
    font-size: 10px !important;
    font-weight: 600 !important;
  }
  .boltBtn {
    font-size: 10px !important;
    margin-top: 0px;
  }
  /* .mainLogo {
    top: 15%;
    width: 30%;
  } */
  .textBanner {
    top: 35%;
    width: 250px;
  }
  .midBannerHead {
    top: 380px;
  }
  .midHeading {
    font-size: 20px !important;
  }

  .school {
    top: 24%;
  }
  .schoolDesc {
    font-size: 5px !important;
  }

  .bannerBrief {
    top: 37%;
    width: 150px;
    font-size: 6px !important ;
  }
  .orangeDesc {
    top: 55%;
    width: 250px;
  }
  .orangeMidPoint {
    margin-top: 60px;
  }
.midDesc1 {
    font-size: 160px !important;
  }

  .midDesc2 {
    font-size: 55px !important;
  } 
  .belowOrangeBand {
    top: 85%;
  }

  .secondTextBanner {
    top: 92%;
    width: 250px;
  }

  .secBriefPos {
    top: 500px;
  }
  .secondBannerSubBrief {
    width: 200px;
    font-size: 8px !important;
  }
  .signUpBtnPos {
    top: 100.5% !important;
    width: 20px !important;
    height: 10px !important;
    font-size: 10px !important;
  }
  /* .formStyle {
    top: 50px;
  } */
  .regBoxSize {
    width: 250px !important;
  }
  .header-base {
    height: 150px;
  }
 
}

@media (min-width: 768px) and (max-width: 911px) {
  .header-base {
    height: 150px;
  }
 
  .registerTextfield {
    width: 120px;
  }
  .registerBox {
    width: 120px;
  }
  .BMSLogo {
    width: 70px;
    height: 70px;
    margin-top: -5px;
    margin-bottom: -30px;
  }
  .regHomeBtn {
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  .logoSize {
    width: 150px;
  }
  /* .register-page-container {
    height: 180vh;
  } */

  .regBoxSize {
    width: 250px !important;
  }

  .regBannerSize {
    width: 550px;
  }
  .regBannerSize {
    width: 400px;
  }
  .regMidHeading {
    font-size: 35px !important;
  }
  .headerBtn {
    top: -5px;
  }
  .orangeMidPoint {
    margin-top: 30px;
  }
  .homeBtn {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .boltBtn {
    font-size: 20px !important;
  }
  /* .mainLogo {
    top: 30%;
  } */
  .textBanner {
    top: 70%;
    width: 400px;
  }
  .midBannerHead {
    top: 340px;
  }

  .midHeading {
    font-size: 50px !important;
  }

  .school {
    top: 45%;
  }
  .bannerBrief {
    top: 440px;
    width: 320px;
    font-size: 12px !important;
  }
  .orangeDesc {
    top: 220px;
    width: 100%;
  }

  .midDesc1 {
    font-size: 130px !important;
  }
  .midDesc2 {
    font-size: 40px !important;
  }
  .belowOrangeBand {
    top: 900px;
  }
  .secondTextBanner {
    width: 450px;
  }
  /* .secondBannerBrief {
    top: 168%;
    font-size: 13px !important;
  } */

  .secBriefPos {
    top: 1000px;
    width: 400px;
  }
  .secondBannerSubBrief {
    width: 340px;
    font-size: 14px !important;
  }
  .footerBox {
    top: 190%;
  }
  .regMainLogoBox {
    top: 150px;
  }
  .regMainLogo {
    width: 300px;
  }
  .regSchoolDesc {
    font-size: 12px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .header-base {
    height: 180px;
  }
  
  .registerTextfield {
    width: 160px;
  }
  .regHeaderBtn {
    top: -15px;
  }
  .BMSLogo {
    width: 80px;
    height: 80px;
    margin-top: -5px;
    margin-bottom: -15px;
  }

  .regBannerSize {
    width: 500px;
  }
  .headerBtn {
    top: -15px;
  }
  /* .mainLogo {
    top: 40%;
    width: 30%;
  } */

  .midBannerHead {
    top: 450px;
  }

  .midHeading {
    font-size: 68px !important;
  }

  /* .school {
    top: 60%;
  } */

  .bannerBrief {
    top: 575px;
    width: 420px;
    font-size: 16px !important;
  }
  .orangeDesc {
    top: 300px;
  }

  .midDesc1 {
    font-size: 155px !important;
  }

  .midDesc2 {
    font-size: 55px !important;
  }
  .belowOrangeBand {
    top: 1200px;
  }
  .textBanner {
    top: 600px;
    width: 500px;
  }
  .secondTextBanner {
    top: 1300px;
    width: 500px;
  }
  /* .secondBannerBrief {
    top: 220%;
  } */
  .secondBannerSubBrief {
    width: 430px;
  }
  /* .signUpBtnPos {
    top: 235% !important;
  } */
  .footerBox {
    top: 1700px !important;
  }
  .regHomeBtn {
    font-size: 30px !important;
    font-weight: 700 !important;
  }
  .logoSize {
    width: 180px;
  }

  .regMainLogoBox {
    top: 200px;
  }
  .regMainLogo {
    width: 400px;
  }
  .secBriefPos {
    top: 1320px;
    width: 400px;
  }
  .boltBtn {
    font-size: 30px !important;
  margin-top: 5px;
  }
}

@media (min-width: 1401px) {
  .midDesc1 {
    font-size: 155px !important;
  }

  .midDesc2 {
    font-size: 55px !important;
  }
   .header-base {
    height: 250px;
    margin-top: -50px;
  }
  .banner-width {
    width: 100%;
  }
  
}
