.bandLandHomeContainer {
  top: 14%;
}

.bandLandRegisterContainer {
  top: 24%;
}

@media (min-width: 320px) and (max-width: 423px) {
  .mobileViewBannerHome {
    height: 1650px;
    width: 320px;
  }

  .mobileViewBannerRegister {
    height: 1150px;
    width: 320px;
  }

  .bandLandHomeContainer {
    top: 6%;
  }

  .bandLandRegisterContainer {
    top: 18%;
  }
}

@media (min-width: 424px) and (max-width: 529px) {
  .mobileViewBannerHome {
    height: 1800px;
    width: 390px;
  }

  .mobileViewBannerRegister {
    height: 1100px;
    width: 400px;
  }

  .bandLandHomeContainer {
    top: 6%;
  }

  .bandLandRegisterContainer {
    top: 18%;
  }
}

@media (min-width: 530px) and (max-width: 599px) {
  .mobileViewBannerHome {
    height: 1830px;
    width: 480px;
  }

  .mobileViewBannerRegister {
    height: 1150px;
    width: 450px;
  }
  .bandLandHomeContainer {
    top: 6%;
  }

  .bandLandRegisterContainer {
    top: 18%;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .mobileViewBannerHome {
    height: 1870px;
    width: 550px;
  }

  .mobileViewBannerRegister {
    height: 1200px;
    width: 450px;
  }
  .bandLandHomeContainer {
    top: 6%;
  }

  .bandLandRegisterContainer {
    top: 18%;
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .mobileViewBannerHome {
    height: 1870px;
    width: 600px;
  }

  .mobileViewBannerRegister {
    height: 1200px;
    width: 600px;
  }
  .bandLandHomeContainer {
    top: 6%;
  }

  .bandLandRegisterContainer {
    top: 18%;
  }
}
